<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeDialog">Cancel</v-btn>
          <v-btn v-if="removePhoto && !cropper" dark text @click="deletePhoto"><v-icon left>mdi-image-remove</v-icon> Remove Photo</v-btn>
          <v-btn v-if="cropper" dark text @click="save">Save</v-btn>
        </v-toolbar-items>

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="warning"
        ></v-progress-linear>
      </v-toolbar>

      <main class="main">
        <div v-if="data.loaded" class="editor">
          <div class="canvas">
            <!-- @dblclick="dblclick" -->
            <img
              ref="image"
              :alt="data.name"
              :src="data.url"
              @loadstart="start"
              @load="start"
            />
          </div>
        </div>

        <div
          v-else
          class="loader"
          @change="change"
          @dragover="dragover"
          @drop="drop"
        >
          <p v-if="!loading">
            Drop image here or
            <label class="browse"
              >browse...
              <input id="file" class="d-sr-only" type="file" accept="image/*" />
            </label>
          </p>
        </div>
      </main>
      <v-bottom-navigation v-if="cropper" style="position:absolute;">
        <v-btn @click="rotateLeft">
          <span>Rotate Left</span>
          <v-icon>mdi-rotate-left</v-icon>
        </v-btn>

        <v-btn @click="rotateRight">
          <span>Rotate Right</span>
          <v-icon>mdi-rotate-right</v-icon>
        </v-btn>

        <v-btn @click="flipHorizontal">
          <span>Flip Horizontal</span>
          <v-icon>mdi-flip-horizontal</v-icon>
        </v-btn>

        <v-btn @click="flipVertical">
          <span>Flip Vertical</span>
          <v-icon>mdi-flip-vertical</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-card>
  </v-dialog>
</template>
<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import Swal from "sweetalert2";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data() {
    return {
      data: {
        cropped: false,
        cropping: false,
        loaded: false,
        name: "",
        previousUrl: "",
        type: "",
        url: "",
      },
      canvasData: null,
      cropBoxData: null,
      croppedData: null,
      cropper: null,
      loading: false,
    };
  },
  props: {
    dialogTitle: {
      type: String,
      default: "Upload Picture",
    },
    removePhoto: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.showDialog();
  },
  methods: {
    read(files) {
      return new Promise((resolve, reject) => {
        if (!files || files.length === 0) {
          resolve();
          return;
        }

        const file = files[0];

        if (/^image\/\w+$/.test(file.type)) {
          if (URL) {
            resolve({
              loaded: true,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
            });
          } else {
            reject(new Error("Your browser is not supported."));
          }
        } else {
          reject(new Error("Please choose an image file."));
        }
      });
    },

    change({ target }) {
      this.read(target.files)
        .then((data) => {
          target.value = "";
          this.update(data);
        })
        .catch((e) => {
          target.value = "";
          this.alert(e);
        });
    },

    dragover(e) {
      e.preventDefault();
    },

    drop(e) {
      e.preventDefault();
      this.read(e.dataTransfer.files)
        .then((data) => {
          this.update(data);
        })
        .catch(this.alert);
    },

    alert(e) {
      //window.alert(e && e.message ? e.message : e);
      Swal.fire({
        icon: "error",
        text: e && e.message ? e.message : e,
      });
    },

    update(data) {
      Object.assign(this.data, data);
    },

    start() {
      const { data } = this;

      if (data.cropped || this.cropper) {
        return;
      }

      this.cropper = new Cropper(this.$refs.image, {
        //autoCrop: false,
        dragMode: "move",
        background: false,
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 0.9,
        initialAspectRatio: 1,

        ready: () => {
          if (this.croppedData) {
            this.cropper
              .crop()
              .setData(this.croppedData)
              .setCanvasData(this.canvasData)
              .setCropBoxData(this.cropBoxData);

            this.croppedData = null;
            this.canvasData = null;
            this.cropBoxData = null;
          }
        },

        crop: ({ detail }) => {
          if (detail.width > 0 && detail.height > 0 && !data.cropping) {
            this.update({
              cropping: true,
            });
          }
        },
      });
    },
    stop() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
    },

    crop() {
      const { cropper, data } = this;

      if (data.cropping) {
        this.croppedData = cropper.getData();
        this.canvasData = cropper.getCanvasData();
        this.cropBoxData = cropper.getCropBoxData();
        this.update({
          cropped: true,
          cropping: false,
          previousUrl: data.url,
          url: cropper
            .getCroppedCanvas(
              data.type === "image/png"
                ? {}
                : {
                    fillColor: "#fff",
                  }
            )
            .toDataURL(data.type),
        });
        this.stop();
      }
    },

    rotateLeft() {
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    },
    flipHorizontal() {
      this.cropper.scaleX(-this.cropper.getData().scaleX || -1);
    },
    flipVertical() {
      this.cropper.scaleY(-this.cropper.getData().scaleY || -1);
    },

    save() {
      this.loading = true;
      this.crop();
      this.$emit("save", this.data.url);

      // this.$store
      //   .dispatch({
      //     type: "setPicture",
      //     //url: '',
      //     imageData: this.data.url,
      //     fileType: this.data.type,
      //   })
      //   .then((res) => {
      //     if (res === false) {
      //       Swal.fire({
      //         icon: "error",
      //         text: "Error updating picture. Picture not being updated.",
      //       });
      //     }
      //     this.loading = false;
      //     this.closeDialog();
      //   });
    },

    /**
     * to be called from parent
     */
    close(res) {
      this.loading = false;
      if (res === false) {
        Swal.fire({
          icon: "error",
          text: "Error updating picture. Picture not being updated.",
        });

      } else {
        this.closeDialog(); // close dialog if success
      }
    },
    deletePhoto() {
      this.loading = true
      this.$emit('delete')
    }
  },
  // watch: {
  //   showDialog() {
  //     if (this.showDialog == false) {
  //       this.$emit("close");
  //     }
  //   },
  // },
  mixins: [dialogMixin],
};
</script>
<style scoped>
.main {
  /* background-color: #333; */
  /* bottom: 0; */
  /* left: 0; */
  position: absolute;
  height: calc(100% - 120px);
  width: 100%;
  /* right: 0; */
  /* top: 3rem; */
  /* top: 4em; */
}

.loader {
  display: table;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.loader > p {
  /* color: #999; */
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.browse {
  color: #0074d9;
  cursor: pointer;
  margin-left: 0.25rem;
}

.browse:hover {
  color: #08f;
  text-decoration: underline;
}

.editor {
  /* position: absolute; */
  /* height: calc(100% - 120px); */
  height: 100%;
  background-color: #333;
  /* width: 100%; */
  /* margin-bottom:30px; */
}

.canvas {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  /* height: 100%;
  padding-bottom: 56px; */
}
.canvas > img {
  max-height: 100%;
  max-width: 100%;
}
</style>
